import { Controller } from "@hotwired/stimulus";
import ahoy from "ahoy.js";

// Connects to data-controller="hadiths--search--form"
export default class extends Controller {
  static targets = ["form", "queryInput", "filters", "openFilters", "closeFilters"]

  connect() {
    if (this.queryInputTarget.value.length != 0 && document.getElementsByClassName("hadiths-search-result").length == 0) {
      this.formTarget.requestSubmit();
    }
  }

  toggleFilters() {
    this.filtersTarget.classList.toggle("flex");
    this.filtersTarget.classList.toggle("hidden");
    this.openFiltersTarget.classList.toggle("hidden");
    this.closeFiltersTarget.classList.toggle("hidden");
  }

  searchResultClicked(event) {
    history.replaceState({ show_results: true }, "");

    ahoy.track("Hadith search result clicked", {
      hadith_id: event.target.dataset.hadithId,
      query: this.queryInputTarget.value,
      type: this.getType(event),
      rank: this.getRank(event),
    });
  }

  updateContent() {
    if (this.queryInputTarget.value.length == 0) {
      document.getElementById("hadiths-search-results-count").innerHTML = "";
      document.getElementById("hadiths-search-results").innerHTML = "";

      document.getElementById("hadiths-search-hideable-content")?.classList.remove("hidden");
    }
  }

  getType(event) {
    return event.target.parentNode.parentNode.parentNode.parentNode.id;
  }

  getRank(event) {
    let child = event.target.parentNode.parentNode;

    return Array.prototype.indexOf.call(child.parentNode.children, child) + 1;
  }
}
