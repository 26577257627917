import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="cue-bookmarks"
export default class extends Controller {
  connect() {
    JSON.parse(document.getElementById("cue-bookmarks-data").dataset.cueIds).forEach(function (cueId) {
      Array.from(document.getElementsByClassName(`cue_${cueId}-bookmark`)).forEach(function (bookmarkElement) {
        bookmarkElement.innerHTML = `\
<a data-turbo-method="post" href="/cues/${cueId}/unbookmark"> \
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="h-5 text-gray-800 dark:text-white ms-3 max-sm:me-1 max-w-5"> \
    <path d="m19 21-7-4-7 4V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v16z"/> \
  </svg> \
</a> \
`
      });
    });
  }
}
