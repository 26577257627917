Turbo.StreamActions.push_state = function () {
  const url = this.getAttribute("url");
  const state = { turbo_stream_history: true };

  Turbo.cache.exemptPageFromPreview();

  history.replaceState(state, "", window.location.href);
  history.pushState(state, "", url);
};

Turbo.StreamActions.replace_state = function () {
  Turbo.cache.exemptPageFromPreview();

  history.replaceState(history.state, "", this.getAttribute("url"));
};

Turbo.StreamActions.hide = function () {
  document.getElementById(this.getAttribute("target"))?.classList.add("hidden");
}
