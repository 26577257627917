import { Controller } from "@hotwired/stimulus";
import ahoy from "ahoy.js";

// Connects to data-controller="transcriptions--search--form"
export default class extends Controller {
  static values = {
    turboFramesPrefix: String,
  }

  static targets = ["form", "queryInput", "filters", "openFilters", "closeFilters"]

  connect() {
    if (this.queryInputTarget.value.length != 0 && document.getElementsByClassName("transcriptions-search-result").length == 0) {
      this.formTarget.requestSubmit();
    }
  }

  toggleFilters() {
    this.filtersTarget.classList.toggle("flex");
    this.filtersTarget.classList.toggle("hidden");
    this.openFiltersTarget.classList.toggle("hidden");
    this.closeFiltersTarget.classList.toggle("hidden");
  }

  searchResultClicked(event) {
    history.replaceState({ show_results: true, turbo_frames_prefix: this.turboFramesPrefixValue }, "");

    ahoy.track("Transcription search result clicked", {
      cue_id: event.target.dataset.cueId,
      query: this.queryInputTarget.value,
      type: this.getType(),
      rank: this.getRank(event),
    });
  }

  updateContent() {
    if (this.queryInputTarget.value.length == 0) {
      document.getElementById(`${this.turboFramesPrefixValue}-transcriptions-search-results-count`).innerHTML = "";
      document.getElementById(`${this.turboFramesPrefixValue}-transcriptions-search-results`).innerHTML = "";
      document.getElementById(`${this.turboFramesPrefixValue}-transcriptions-pagination`).innerHTML = "";

      document.getElementById(`${this.turboFramesPrefixValue}-transcriptions-search-hideable-content`)?.classList.remove("hidden");
    }
  }

  getType() {
    let type = "home";

    const pathMatch = window.location.pathname.match(/\/(speakers|playlists|media)\/(.+)$/);
    if (pathMatch) {
      type = pathMatch[1];
    }

    return type;
  }

  getRank(event) {
    let card = event.target.closest('div');

    return Array.prototype.indexOf.call(card.parentNode.children, card) + 1;
  }
}
