import { initFlowbite } from "flowbite";

window.addEventListener("turbo:before-stream-render", (event) => {
  const originalRender = event.detail.render;

  event.detail.render = function (streamElement) {
    originalRender(streamElement);
    initFlowbite();
  };
});

window.addEventListener("turbo:frame-render", (event) => {
  if (event.target.id.endsWith("-transcriptions-search-result-wrapper")) {
    document.getElementById("back-to-results-button").classList.add("flex");
    document.getElementById("back-to-results-button").classList.remove("hidden");

    document.getElementById(event.target.id).classList.remove("hidden");
    document.getElementById(`${event.target.id.replace("-transcriptions-search-result-wrapper", "")}-transcriptions-search-wrapper`).classList.add("hidden");

    document.body.scrollTop = document.documentElement.scrollTop = 0;
  } else if (event.target.id.endsWith("-search-result-wrapper")) {
    if (document.getElementById("back-to-results-button")) {
      const service = event.target.id.replace("-search-result-wrapper", "");

      document.getElementById("back-to-results-button").classList.add("flex");
      document.getElementById("back-to-results-button").classList.remove("hidden");

      document.getElementById(`${service}-search-result-wrapper`).classList.remove("hidden");
      document.getElementById(`${service}-search-wrapper`).classList.add("hidden");

      Array.from(document.getElementById(`${service}-search-result-wrapper`).getElementsByClassName(`${service}-navigation-link`)).forEach(function (link) {
        link.dataset.turboAction = "replace";
      });
    }

    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }
});
