import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="fillers--content-filler"
export default class extends Controller {
  static values = {
    // meta tag name or HTML tag ID.
    source: String,
    // 'auto', 'meta', or 'element'. 'auto' sourceType will prioritize 'meta' over 'element'.
    // If meta tag with name value equal to source is not exists, then it will use source as HTML tag ID value.
    sourceType: { type: String, default: 'auto' }
  }

  connect() {
    const metaContent = document.querySelector(`meta[name="${this.sourceValue}"]`)?.content
    const elementContent = document.getElementById(this.sourceValue)?.innerHTML

    if (this.sourceTypeValue === 'auto') {
      if (metaContent != undefined) {
        this.element.innerHTML = metaContent
      } else if (elementContent != undefined) {
        this.element.innerHTML = elementContent
      }
    } else if (this.sourceTypeValue === 'meta') {
      this.element.innerHTML = metaContent
    } else if (this.sourceTypeValue === 'element') {
      this.element.innerHTML = elementContent
    }
  }
}
