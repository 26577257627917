// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import "./controllers/third_parties"

import "./custom_turbo_events"
import "./custom_turbo_streams"
import gtag from "./google_analytics"
import "./local_time_settings"
import "./popstate_override"

import "flowbite/dist/flowbite.turbo.js"
