import { Controller } from "@hotwired/stimulus"
import SlimSelect from 'slim-select'

// Connects to data-controller="slim-select"
export default class extends Controller {
  static values = {
    placeholderText: String,
    searchText: String,
    searchPlaceholderText: String
  }

  connect() {
    this.select = new SlimSelect({
      select: this.element,
      settings: {
        placeholderText: this.placeholderTextValue,
        searchText: this.searchTextValue,
        searchPlaceholder: this.searchPlaceholderTextValue,
        hideSelected: true,
      }
    })
  }

  disconnect() {
    this.select.destroy()
  }
}
