import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="content-toggler"
export default class extends Controller {
  static values = {
    primaryId: String,
    secondaryId: String,
  }

  connect() {
    this.currentContent = "primary";
  }

  toggle() {
    if (this.currentContent == "primary") {
      this.currentContent = "secondary";
      this.element.innerHTML = document.getElementById(this.secondaryIdValue).innerHTML;
    } else {
      this.currentContent = "primary";
      this.element.innerHTML = document.getElementById(this.primaryIdValue).innerHTML;
    }
  }
}
