import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="shamela--tashkeel"
export default class extends Controller {
  static targets = ["content", "originalContent"];

  static values = {
    show: String,
    hide: String,
  }

  connect() {
    this.showen = true;
  }

  toggle(event) {
    if (this.showen == true) {
      const getTextNodes = (el) => {
        let textNodes = [];

        if (el.nodeType === Node.TEXT_NODE) {
          textNodes.push(el);
        } else {
          for (const child of el.childNodes) {
            textNodes = textNodes.concat(getTextNodes(child));
          }
        }

        return textNodes;
      };

      const textNodes = getTextNodes(this.contentTarget);

      for (const node of textNodes) {
        if (node.textContent.match(/[៖ـ\u0600-\u06FF]/g)) {
          node.textContent = node.textContent.replace(/[ًٌٍَُِّْ]+/g, "");
        }
      }

      event.target.innerHTML = this.showValue;
    } else {
      this.contentTarget.innerHTML = this.originalContentTarget.innerHTML;

      event.target.innerHTML = this.hideValue;
    }

    this.showen = !this.showen;
  }
}
