import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="hider"
export default class extends Controller {
  static values = {
    dependencyType: String,
    dependency: String
  }

  connect() {
    if (this.dependencyTypeValue == "id") {
      this.handleIdDependency();
    } else if (this.dependencyTypeValue == "class") {
      this.handleClassDependency();
    }
  }

  handleIdDependency() {
    if (!document.getElementById(this.dependencyValue)) {
      this.element.classList.add("!hidden");
    }
  }

  handleClassDependency() {
    if (!document.getElementsByClassName(this.dependencyValue)) {
      this.element.classList.add("!hidden");
    }
  }
}
