import { Controller } from "@hotwired/stimulus"
import { Tabs } from "flowbite"

// Connects to data-controller="flowbite--tab-manager"
export default class extends Controller {
  static values = {
    tabIds: Array,
    tabType: String
  }

  static targets = ["tabElement"]

  connect() {
    const tabElements = this.tabIdsValue.map((tab) => {
      return {
        id: tab,
        triggerEl: document.querySelector(`#${tab}-tab`),
        targetEl: document.querySelector(`#${tab}`),
      }
    })

    let options;

    if (this.tabTypeValue === "full-width") {
      options = {
        activeClasses: 'font-bold text-primary-600 hover:text-primary-600 dark:text-primary-500 dark:hover:text-primary-400 border-primary-600 dark:border-primary-500'
      };
    } else if (this.tabTypeValue === "vertical") {
      options = {
        activeClasses: 'text-white bg-primary-600',
        inactiveClasses: 'hover:text-gray-900 bg-gray-50 hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-700 dark:hover:text-white'
      };
    } else if (this.tabTypeValue === "underline") {
      options = {
        activeClasses: 'text-primary-600 hover:text-primary-600 dark:text-primary-500 dark:hover:text-primary-500 border-primary-600 dark:border-primary-500'
      };
    }

    this.tabs = new Tabs(this.tabElementTarget, tabElements, options);
  }

  changeActiveTab(event) {
    this.tabs.show(event.target.value)
  }
}
