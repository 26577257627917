window.addEventListener("popstate", (event) => {
  if (event.state && event.state.show_results) {
    const transcriptionsSearchResultWrapper = document.getElementById(`${event.state.turbo_frames_prefix}-transcriptions-search-result-wrapper`);
    if (transcriptionsSearchResultWrapper) { transcriptionsSearchResultWrapper.innerHTML = "" }
    document.getElementById(`${event.state.turbo_frames_prefix}-transcriptions-search-wrapper`)?.classList.remove("hidden");

    const hadithsSearchResultWrapper = document.getElementById("hadiths-search-result-wrapper");
    if (hadithsSearchResultWrapper) { hadithsSearchResultWrapper.innerHTML = "" }
    document.getElementById("hadiths-search-wrapper")?.classList.remove("hidden");

    const shamelaSearchResultWrapper = document.getElementById("shamela-search-result-wrapper");
    if (shamelaSearchResultWrapper) { shamelaSearchResultWrapper.innerHTML = "" }
    document.getElementById("shamela-search-wrapper")?.classList.remove("hidden");

    document.getElementById("back-to-results-button").classList.add("hidden");
    document.getElementById("back-to-results-button").classList.remove("flex");
  } else if (event.state && event.state.turbo_stream_history) {
    Turbo.visit(window.location.href, { action: "replace" });
  }
});
