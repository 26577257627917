import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="fillers--form-filler"
export default class extends Controller {
  static values = {
    form: String,
    submit: Boolean,
    data: Array
  }

  fill() {
    const form = document.getElementById(this.formValue);

    this.dataValue.forEach((target) => {
      if (target['type'] == 'input') {
        form.querySelector(`#${target['element_id']}`).value = target['value'];
      }
    });

    if (this.submitValue) {
      form.requestSubmit();
    }
  }
}
