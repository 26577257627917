import { Controller } from "@hotwired/stimulus";
import ahoy from "ahoy.js";

// Connects to data-controller="shamela--search--form"
export default class extends Controller {
  static targets = ["form", "queryInput", "filters", "openFilters", "closeFilters"]

  connect() {
    if (this.queryInputTarget.value.length != 0 && document.getElementsByClassName("shamela-search-result").length == 0) {
      this.formTarget.requestSubmit();
    }
  }

  toggleFilters() {
    this.filtersTarget.classList.toggle("flex");
    this.filtersTarget.classList.toggle("hidden");
    this.openFiltersTarget.classList.toggle("hidden");
    this.closeFiltersTarget.classList.toggle("hidden");
  }

  searchResultClicked(event) {
    history.replaceState({ show_results: true }, "");

    ahoy.track("Shamela search result clicked", {
      hadith_id: event.target.dataset.hadithId,
      query: this.queryInputTarget.value,
    });
  }

  updateContent() {
    if (this.queryInputTarget.value.length == 0) {
      document.getElementById("shamela-search-results-count").innerHTML = "";
      document.getElementById("shamela-search-results").innerHTML = "";

      document.getElementById("shamela-search-hideable-content")?.classList.remove("hidden");
    }
  }
}
