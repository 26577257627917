import { Controller } from "@hotwired/stimulus";
import { Dropdown } from "flowbite";

// Connects to data-controller="theme"
export default class extends Controller {
  static targets = ["dropdown", "lightIcon", "darkIcon"];

  connect() {
    if (localStorage.getItem("color-theme") == undefined) {
      localStorage.setItem("color-theme", "auto");
    }

    this.updateTheme();

    window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", this.updateTheme.bind(this));
  }

  auto() {
    localStorage.setItem("color-theme", "auto");

    this.updateTheme();
  }

  dark() {
    localStorage.setItem("color-theme", "dark");

    this.updateTheme();
  }

  light() {
    localStorage.setItem("color-theme", "light");

    this.updateTheme();
  }

  updateTheme() {
    let theme = localStorage.getItem("color-theme");

    if (theme === "dark" || (theme === "auto" && window.matchMedia("(prefers-color-scheme: dark)").matches)) {
      document.documentElement.classList.add("dark");

      this.lightIconTarget.classList.add("hidden");
      this.darkIconTarget.classList.remove("hidden");
    } else {
      document.documentElement.classList.remove("dark");

      this.darkIconTarget.classList.add("hidden");
      this.lightIconTarget.classList.remove("hidden");
    }

    this.dispatch("updateTheme");

    new Dropdown(this.dropdownTarget, document.getElementById("theme-dropdown-button")).hide();
  }
}
